import React, { useState } from "react"
import { navigate } from "gatsby"
import { handleLogin, strapiLogin, isLoggedIn, isAFPABranch } from "../../services/auth"
import TextField from "../common/form/text-field/text-field"
import { useForm } from "react-form"
import styles from "./login.module.scss"
import ConfirmNavLink from "../common/confirm-nav-link/confirm-nav-link"
import ModalWindow from "../common/modal-window/modal-window"
import ForgotPassword from "../common/forgot-password/forgot-password"
import auth from "../../services/auth"
import { MdError, MdVisibility, MdVisibilityOff } from "react-icons/md"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button } from "@mui/material"
import { useEffect } from "react"


const Login = ({ siteTitle }) => {
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState(() =>
    localStorage.checkbox ? localStorage.user : ""
  );
  const [password, setPassword] = useState(() =>
    localStorage.checkbox ? localStorage.password : ""
  );
  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  const [isChecked, setIsChecked] = useState(false);



  const defaultValues = React.useMemo(() => {
    return { user: "", password: "" }
  }, [])

  useEffect(() => {
    const savedCheckboxValue = localStorage.getItem('checkbox')
    console.log('savedCheckboxValue', savedCheckboxValue);

    if (savedCheckboxValue === "1") {
      setIsChecked(true);
      const savedUser = window.localStorage.getItem('user');
      const savedPassword = window.localStorage.getItem('password');

      if (savedUser && savedPassword) {
        setValues({ user: savedUser, pass: savedPassword });
      }
    } else {
      // If savedCheckboxValue is empty, clear the values
      setIsChecked(false);
      setValues({ user: '', pass: '' });
      localStorage.removeItem('user');
      localStorage.removeItem('password');
    }

  }, [setValues]);

  const {
    Form,
    setValues,
    meta: { canSubmit },

  } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      console.log("values", values)

      if (isChecked && user !== "") {
        setUser(localStorage.user = values.user);
        setPassword(localStorage.password = values.pass);
        localStorage.checkbox = isChecked ? "1" : "";
      }
      else {
        localStorage.removeItem('user');
        localStorage.removeItem('password');
        localStorage.removeItem('checkbox');
      }

      if (isAFPABranch()) {
        handleLogin(values).then(
          result => {
            navigate("/")
          },
          error => {
            setShowMessage(true)
            setError(error.message)
          }
        )
      }
      else {
        handleLogin(values).then(
          result => {
            navigate("/")
          },
          error => {
            setShowMessage(true)
            setError(error.message)
          }
        )
      }

    },
    validate: () => {
      setShowMessage(false)
    },
  })


  if (isLoggedIn()) {
    navigate(`/`)
  }

  const getLoginError = () => {
    if (showMessage) {
      return (
        <div className={styles.loginError}>
          <MdError /> {error}
        </div>
      )
    }
  }

  const onClick = () => {
    setModalOpen(true)
  }


  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }
    if (modalOpen) {
      return (
        <ModalWindow title="Forgot Password" closeHandler={onModalClose}>
          <ForgotPassword />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }



  return (
    <div className={classNames(styles.wrapper, "login-bg")}>
      <div
        id="headerLogo"
        className={classNames(styles.loginLogo, "login-logo")}
      >
        <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
      </div>
      <div className={styles.login}>
        <div className={styles.loginOverlay}>
          <Form className={styles.formSignin}>
            <h2 className={styles.loginTitle}>Sign In</h2>
            {auth.isAFPABranch() &&
              <div className={styles.loginDescription}>
                Don't have an account yet?
                <ConfirmNavLink to={"/app/signup"} className={styles.signUp}>
                  {' Sign Up'}
                </ConfirmNavLink>
              </div>
            }
            {getLoginError()}
            {getModalWindow()}
            <div className={styles.fields}>
              <TextField
                field="user"
                value={user}
                name="user"
                placeholder="Username"
                prependIcon="MdPerson"
                onChange={(e) => setUser(e.target.value)}
                required
              />
              <div>
                <TextField
                  type={showPassword ? "text" : "password"}
                  field="pass"
                  value={password}
                  name="pass"
                  placeholder="Password"
                  prependIcon="MdLock"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  trailingIcon={
                    password && password.length > 0 && (
                      <Button style={{
                        background: 'transparent',
                        border: 'none',
                        fontSize: '1.2rem',
                        marginTop: '15px',
                        cursor: 'pointer',
                        padding: '0 8px'
                      }} onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <MdVisibilityOff style={{ color: '#666' }} /> : <MdVisibility style={{ color: '#666' }} />}
                      </Button>
                    )
                  }

                />

              </div>
            </div>
            <input
              type="checkbox"
              checked={isChecked}
              name="lsRememberMe"
              onChange={(e) => setIsChecked(e.target.checked)}
              style={{ marginRight: '8px', cursor: 'pointer' }}
            />
            <label>Remember me</label>

            <input
              className={styles.loginBtn}
              id="loginButton"
              type="submit"
              value="Sign In"
              disabled={!canSubmit}
            />
            {auth.isAFPABranch() &&
              <div className={styles.pointer}>
                <a id="forgot-password" onClick={onClick}>
                  Forgot Password?
                </a>
              </div>
            }
          </Form>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  siteTitle: PropTypes.string,
}

Login.defaultProps = {
  siteTitle: ``,
}

export default Login
