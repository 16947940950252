import PropTypes from "prop-types"
import React, { useState } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-form"
import { MdError, MdCheck } from "react-icons/md"
import { forgotPasswordSubmit } from "../../../services/auth"
import TextField from "../form/text-field/text-field"
import styles from "./forgot-password-submit.module.scss"
import classNames from "classnames"

const ForgotPasswordSubmit = (email) => {

  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const defaultValues = React.useMemo(() => {
    return { email: email.email }
  }, [])
  const {
    Form,
    meta: { canSubmit },
  } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      console.log('Values:', values)
      forgotPasswordSubmit(values).then(
        result => {
          console.log('Result:', result)
          setShowMessage(true)
          setSuccess('Password has been updated')
        },
        error => {
          setShowMessage(true)
          setError(error.message)
        }
      )
    },
    validate: () => {
      setShowMessage(false)
    },
  })

  const getError = () => {
    if (showMessage) {
      if (error) {
        return (
          <div className={styles.loginError}>
            <MdError /> {error}
          </div>
        )
      } else {
        return (
          <div className={styles.loginSuccess}>
            <MdCheck /> {success}
          </div>
        )
      }  
    }
  }

    return (
      <div className={styles.login}>
        <div className={styles.loginOverlay}>
          <Form className={styles.formSignin}>
            <div>
                Please provide your activation code and a new password. 
            </div>
            {getError()}
            <div className={styles.fields}>
              <TextField
                field="email"
                prependIcon="MdEmail"
                placeholder="Email"
                required
                disabled
              />
              <TextField
                type="password"
                field="password"
                placeholder="New Password"
                prependIcon="MdLock"
                required
              />
              <TextField
                field="code"
                placeholder="Activation Code"
                required
              />
            </div>
            <input
              className={styles.loginBtn}
              id="ResetPassword"
              type="submit"
              value="Reset Password"
            />
          </Form>
        </div>
      </div>
  )


}

export default ForgotPasswordSubmit