import PropTypes from "prop-types"
import React, { useState } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-form"
import { MdError } from "react-icons/md"
import { forgotPassword } from "../../../services/auth"
import TextField from "../form/text-field/text-field"
import ModalWindow from "../modal-window/modal-window"
import ForgotPasswordSubmit from "../forgot-password-submit/forgot-password-submit"
import styles from "./forgot-password.module.scss"
import classNames from "classnames"

const ForgotPassword = () => {

  const [showMessage, setShowMessage] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState()
  const [error, setError] = useState()

  const defaultValues = React.useMemo(() => {
    return { email: "" }
  }, [])
  const {
    Form,
    meta: { canSubmit },
  } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      console.log('Values:', values)
      forgotPassword(values.email).then(
        result => {
          setEmail(values.email)  
          setModalOpen(true)
          setShowMessage(false)
        },
        error => {
          setShowMessage(true)
          setError(error.message)
        }
      )
    },
    validate: () => {
      setShowMessage(false)
    },
  })

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }
    if (modalOpen) {
      return (
        <ModalWindow title="Change Password" closeHandler={onModalClose}>
          <ForgotPasswordSubmit email={email}/>
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const getError = () => {
    if (showMessage) {
      return (
        <div className={styles.loginError}>
          <MdError /> {error}
        </div>
      )
    }
  }

    return (
      <div className={styles.login}>
        <div className={styles.loginOverlay}>
          <Form className={styles.formSignin}>
            <div>
                Please provide the email that you are registered with 
            </div>
            {getError()}
            {getModalWindow()}
            <div className={styles.fields}>
              <TextField
                field="email"
                prependIcon="MdEmail"
                placeholder="Email"
                required
              />
            </div>
            <input
              className={styles.loginBtn}
              id="SendEmailButton"
              type="submit"
              value="Send Verification Email"
            />
          </Form>
        </div>
      </div>
  )


}

export default ForgotPassword