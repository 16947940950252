import React, { useState } from "react"
import { navigate } from "gatsby"
import { handleSignup, isLoggedIn } from "../../services/auth"
import TextField from "../common/form/text-field/text-field"
import { useForm } from "react-form"
import styles from "./signup.module.scss"
import ConfirmNavLink from "../common/confirm-nav-link/confirm-nav-link"
import ModalWindow from "../common/modal-window/modal-window"
import ConfirmUser from "../common/confirm-user/confirm-user"
import ResendConfirmCode from "../common/resend-confirm-code/resend-confirm-code"
import organizations from "../../services/organizations"
import { MdError } from "react-icons/md"
import PropTypes from "prop-types"
import classNames from "classnames"

const Signup = ({ siteTitle }) => {
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [resendOpen, setResendOpen] = useState(false)
  const [user, setUser] = useState('')


  const defaultValues = React.useMemo(() => {
    return { email: "", password: "", signUpCode: "" }
  }, [])
  const {
    Form,
    meta: { canSubmit },
  } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      console.log('Values:', values.signUpCode)

      organizations.findByCode(values.signUpCode).then(
        response => {
          console.log('Response', response)

          if (response.entity) {
            const id = response.entity.id

            const userObj = {
              ...values,
              id
            }

            console.log('user:', userObj)

            handleSignup(userObj).then(
              result => {
                setUser(userObj)
                setConfirmOpen(true)
              },
              error => {
                setShowMessage(true)
                setError(error.message)
              }
            )
          }
          else {
            setShowMessage(true)
            setError("Sign Up Code not found")
          }
        }
      )  

    },
    validate: () => {
      setShowMessage(false)
    },
  })

  if (isLoggedIn()) {
    navigate(`/`)
  }

  const onResendClick = () => {
    setResendOpen(true)
  }


  const getLoginError = () => {
    if (showMessage) {
      return (
        <div className={styles.loginError}>
          <MdError /> {error}
        </div>
      )
    }
  }

  const getConfirmWindow = () => {
    const onModalClose = () => {
      setConfirmOpen(false)
    }
    if (confirmOpen) {
      return (
        <ModalWindow title="Confirmation" closeHandler={onModalClose}>
          <ConfirmUser email={user.email}/>
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const getResendWindow = () => {
    const onModalClose = () => {
      setResendOpen(false)
    }
    if (resendOpen) {
      return (
        <ModalWindow title="Resend Confirmation" closeHandler={onModalClose}>
          <ResendConfirmCode />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  return (
    <div className={classNames(styles.wrapper, "login-bg")}>
      <div
        id="headerLogo"
        className={classNames(styles.loginLogo, "login-logo")}
      >
        <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
      </div>
      <div className={styles.login}>
        <div className={styles.loginOverlay}>
          <Form className={styles.formSignin}>
            <h3 className={styles.loginTitle}>Get started contributing today</h3>
            {getLoginError()}
            {getConfirmWindow()}
            {getResendWindow()}
            <div className={styles.fields}>
              <TextField
                field="email"
                placeholder="Work Email"
                prependIcon="MdEmail"
                required
              />
              <TextField
                type="password"
                field="password"
                placeholder="Password"
                prependIcon="MdLock"
                required
              />
              <div className={styles.passwordCriteria}>
                <h6>Password Criteria</h6>
                <ul>
                  <li>Minimum password length is 8 characters</li>
                  <li>Requires 1 or more numbers</li>
                  <li>Requires 1 or more special character</li>
                  <li>Requires 1 or more uppercase letters</li>
                  <li>Requires 1 or more lowercase letters</li>
                </ul>
              </div>
              <TextField
                field="signUpCode"
                placeholder="Activation Code"
                required
              />
            </div>
            <input
              className={styles.loginBtn}
              id="SignupButton"
              type="submit"
              value="Sign Up"
            />

            <div className={styles.loginDescription}>
              Already have an account?
              <ConfirmNavLink to={"/app/login"}>
                  {' Sign In'}
              </ConfirmNavLink>
              <div className={styles.pointer}>
                <a id="forgot-code" onClick={onResendClick}>
                    Resend Activation Code
                </a>
              </div>  
              <div>By signing up you agree to the 
                <ConfirmNavLink to={"/terms"}> Terms of Use</ConfirmNavLink> and 
                <ConfirmNavLink to={"/privacy"}> Privacy Policy</ConfirmNavLink>
              </div>
            </div>  
          </Form>
        </div>
      </div>
    </div>
  )
}

Signup.propTypes = {
  siteTitle: PropTypes.string,
}

Signup.defaultProps = {
  siteTitle: ``,
}

export default Signup
