import PropTypes from "prop-types"
import React, { useState } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-form"
import { MdError } from "react-icons/md"
import { resendConfirmation } from "../../../services/auth"
import ModalWindow from "../modal-window/modal-window"
import ConfirmUser from "../confirm-user/confirm-user"
import TextField from "../form/text-field/text-field"
import styles from "./resend-confirm-code.module.scss"
import classNames from "classnames"

const ResendConfirmCode = () => {

  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [email, setEmail] = useState()

  const defaultValues = React.useMemo(() => {
    return { email: "" }
  }, [])
  const {
    Form,
    meta: { canSubmit },
  } = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      console.log('Values:', values)
      resendConfirmation(values.email).then(
        result => {
          setConfirmOpen(true)
          setEmail(values.email)
        },
        error => {
          setShowMessage(true)
          setError(error.message)
        }
      )
    },
    validate: () => {
      setShowMessage(false)
    },
  })

  const getError = () => {
    if (showMessage) {
      return (
        <div className={styles.loginError}>
          <MdError /> {error}
        </div>
      )
    }
  }

  const getConfirmWindow = () => {
    const onModalClose = () => {
      setConfirmOpen(false)
    }
    if (confirmOpen) {
      return (
        <ModalWindow title="Confirmation" closeHandler={onModalClose}>
          <ConfirmUser email={email}/>
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

    return (
      <div className={styles.login}>
        <div className={styles.loginOverlay}>
          <Form className={styles.formSignin}>
            <div>
              Please enter your email to resend confirmation code
            </div>
            {getError()}
            {getConfirmWindow()} 
            <div className={styles.fields}>
              <TextField
                field="email"
                placeholder="Email"
                prependIcon="MdEmail"

                required
              />
            </div>
            <input
              className={styles.loginBtn}
              id="ResendButton"
              type="submit"
              value="Resend"
            />
          </Form>
        </div>
      </div>
  )


}

export default ResendConfirmCode